const settings = [
  {
    path: '/booker/index',
    name: 'booker',
    component: () => import('@/views/booker/index.vue'),
    meta: {
      title: '订舱人'
    }
  },
  {
    path: '/customs/setting',
    name: 'customssetting',
    component: () => import('@/views/customs-setting/index.vue'),
    meta: {
      title: '海关申请单配置'
    }
  },
  {
    path: '/setting/logistics/setting',
    name: 'logisticssetting',
    component: () => import('@/views/setting/logistics-setting.vue'),
    meta: {
      title: '手机号证件号限制配置'
    }
  },
  {
    path: '/setting/logistics/agencysetting',
    name: 'logisticssetting',
    component: () => import('@/views/setting/logistics-setting-agency.vue'),
    meta: {
      title: '手机号证件号限制配置'
    }
  }
]
export default settings
// 配置相关
